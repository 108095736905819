<template>
  <div class="col-12 c g">
    <div class="card card-body">
        <div class="col-12 table-responsive">
            <table class="table table-hover table-bordered table-sm">
                <thead>
                    <th>
                        الطالب
                    </th>
                    <th>
                        الصف
                    </th>
                    <th>
                        الفصل
                    </th>
                    <th>
                        الحالة الصحية
                    </th>
                </thead>
                <tbody>
                    <tr v-for="h in healths" :key="h._id">
                        <td>
                            {{ h.name }}
                        </td>
                        <td>
                            {{ h.classname }}
                        </td>
                        <td>
                            {{ h.classroom }}
                        </td>
                        <td>
                            {{ h.health }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
  </div>
</template>

<script>
import { BFormCheckbox } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
    components: {
        BFormCheckbox,
    },
    directives: {
        Ripple,
    },
    data(){
        return {
            teacher: JSON.parse(localStorage.getItem('teacher')),
            healths: []
        }
    },
    created(){
        var g = this;
        $.post(api + '/teacher/healths', {
            username: this.teacher.username,
            password: this.teacher.password
        }).then(function(r){
            g.loading = false
            if(r.status != 100){
                alert(r.response)
            }else{
                g.healths = r.response;
            }
        }).fail(function(){
        })
    },
    methods: {
    }
}
</script>

<style>
.classroom{
    padding: 15px; 
    border: 2px solid #ddd;
    border-radius: 5px;
}
.classroom:hover{
    background: #fafafa !important;
    cursor: pointer;
    border: 2px solid #333;
}
</style>